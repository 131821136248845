import Transaction from "./../Transaction/Transaction";

const Transactions = ({
  i_transactions,
  fi_openEdit,
  i_showMessage = true,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: "200px" }}>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {!i_transactions.length && i_showMessage && (
            <p>
              <strong>
                *** Step 2: Click on the 'Fill Categories' button above, then
                fill your categories using the current balance of your bank
                account.
              </strong>
            </p>
          )}
          {i_transactions.map((transaction) => (
            <Transaction
              key={transaction.transactionID}
              i_transaction={transaction}
              fi_openEdit={fi_openEdit}
            />
          ))}
        </>
      )}
    </>
  );
};

export default Transactions;
