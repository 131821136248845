import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Terms.css";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let navigate = useNavigate();

  return (
    <div className='terms-and-conditions-page'>
      {/* Navigation */}
      <nav
        className='navbar navbar-expand-lg navbar-dark'
        style={{ backgroundColor: "#2b6777" }}>
        <div className='container'>
          <a className='navbar-brand' href='/'>
            Easy Breezy Budget
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  Home
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Terms and Conditions Content */}
      <div className='terms-content'>
        <div className='container'>
          <h1>Terms and Conditions</h1>
          <p>Last updated: August 1st 2024</p>

          <section>
            <h2>1. Acceptance of Terms</h2>
            <p>
              By accessing or using Easy Breezy Budget, you agree to be bound by
              these Terms and Conditions and all applicable laws and
              regulations. If you do not agree with any part of these terms, you
              may not use our service.
            </p>
          </section>

          <section>
            <h2>2. Use of Service</h2>
            <p>
              You may use Easy Breezy Budget for your personal, non-commercial
              use only. You must not use our service for any illegal or
              unauthorized purpose.
            </p>
          </section>

          <section>
            <h2>3. User Account</h2>
            <p>
              To use certain features of our service, you may be required to
              create an account. You are responsible for maintaining the
              confidentiality of your account and password.
            </p>
          </section>

          <section>
            <h2>4. Privacy Policy</h2>
            <p>
              Your use of Easy Breezy Budget is also governed by our Privacy
              Policy. Please review our Privacy Policy, which also governs the
              site and informs users of our data collection practices.
            </p>
          </section>

          <section>
            <h2>5. Intellectual Property</h2>
            <p>
              The content, features, and functionality of Easy Breezy Budget are
              owned by us and are protected by international copyright,
              trademark, patent, trade secret, and other intellectual property
              laws.
            </p>
          </section>

          <section>
            <h2>6. Termination</h2>
            <p>
              We may terminate or suspend your account and bar access to the
              service immediately, without prior notice or liability, under our
              sole discretion, for any reason whatsoever and without limitation.
            </p>
          </section>

          <section>
            <h2>7. Limitation of Liability</h2>
            <p>
              In no event shall Easy Breezy Budget, nor its directors,
              employees, partners, agents, suppliers, or affiliates, be liable
              for any indirect, incidental, special, consequential or punitive
              damages, including without limitation, loss of profits, data, use,
              goodwill, or other intangible losses, resulting from your access
              to or use of or inability to access or use the service.
            </p>
          </section>

          <section>
            <h2>8. Changes to Terms</h2>
            <p>
              We reserve the right to modify or replace these Terms at any time.
              If a revision is material, we will provide at least 30 days'
              notice prior to any new terms taking effect.
            </p>
          </section>

          <section>
            <h2>9. Contact Us</h2>
            <p>
              If you have any questions about these Terms, please contact us at:
              support_team@easybreezybudget.com
            </p>
          </section>
        </div>
      </div>

      {/* Footer */}
      <footer
        className='py-4'
        style={{ backgroundColor: "#2b6777", color: "#ffffff" }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h5>Easy Breezy Budget</h5>
              <p>Simplifying personal finance management for everyone.</p>
            </div>
            <div className='col-md-3'>
              <h5>Contact</h5>
              <ul className='list-unstyled'>
                <li>Email: support@easybreezybudget.com</li>
              </ul>
            </div>
          </div>
          <hr style={{ backgroundColor: "#ffffff" }} />
          <div className='text-center'>
            <p>
              &copy; {new Date().getFullYear()} 1034Enterprises. All rights
              reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TermsAndConditions;
