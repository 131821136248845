import SetupEnvelopes from "../../Components/SetupEnvelopes/SetupEnvelopes";
import AddEnvelope from "../../Components/AddEnvelope/AddEnvelope";
import CategoryGrid from "../../Components/CategoryGrid/CategoryGrid";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFromAPI } from "../../Components/lib/api";
import "./Envelope.css";

const Envelope = () => {
  let navigate = useNavigate();
  const [envelopes, setEnvelopes] = useState([]);
  const [showAddEnvelope, setShowAddEnvelope] = useState(false);
  const [envelope, setEnvelope] = useState([]);
  const [viewMode, setViewMode] = useState("common"); // "common" or "manual"
  const [loading, setLoading] = useState(true);
  // New state variable to track bulk operations
  const [isBulkOperationComplete, setIsBulkOperationComplete] = useState(false);

  const strURL_API = `${process.env.REACT_APP_API_URL}/api`;
  const strURL_Envelopes = `${process.env.REACT_APP_API_URL}/api/envelope`;
  const theToken = sessionStorage.getItem("token");

  // ** FUNCTION: Get envelopes from database.
  const getEnvelopes = async () => {
    setLoading(true);
    const envelopesFromServer = await getFromAPI(theToken, strURL_Envelopes);
    setEnvelopes(envelopesFromServer);
    setLoading(false);
  };

  // ** FUNCTION (API): Add an envelope to the database.
  const addEnvelopeToDB = async (envelope) => {
    let strURL = "";
    let strMethod = "";
    if (envelope.envelopeID > 0) {
      strURL = `${strURL_Envelopes}/${envelope.envelopeID}`;
      strMethod = "PUT";
    } else {
      strURL = `${strURL_Envelopes}`;
      strMethod = "POST";
    }

    const formData = new URLSearchParams();
    formData.append("envelopeName", envelope.envelopeName);
    formData.append("position", envelope.position);
    formData.append("type", envelope.type);
    formData.append("fullAmount", envelope.fullAmount);
    formData.append("showProgress", envelope.showProgress);
    formData.append("showProgressTick", envelope.showProgressTick);
    formData.append("dueDate", envelope.dueDate);
    formData.append("timeFrame", envelope.timeFrame);

    const response = await fetch(strURL, {
      method: strMethod,
      headers: {
        Accept: "application/json",
        authorization: "Bearer " + theToken,
        Content_Type: "application/x-www-form-urlencoded",
      },
      body: formData,
    });

    if (!response.ok) {
      const data = await response.json();
      // Create an error object with the status and message
      const error = new Error(data.message || "Failed to save category");
      error.status = response.status;
      error.data = data;
      throw error;
    }

    return response;
  };

  // ** FUNCTION: Sent to component: Add an envelope.
  const addEnvelope = async (envelope) => {
    try {
      await addEnvelopeToDB(envelope);

      // Only refresh the envelopes if not in a bulk operation
      if (!isBulkOperationComplete) {
        await getEnvelopes();
      }

      if (envelope.stayOpen === false) {
        setShowAddEnvelope(false);
      }

      return true;
    } catch (error) {
      // Rethrow the error so the modal can handle it
      throw error;
    }
  };

  // ** FUNCTION (API): Delete the specified envelope from the database.
  const deleteEnvelopeFromDB = async (id) => {
    const strURL = `${strURL_Envelopes}/${id}`;
    const response = await fetch(strURL, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        authorization: "Bearer " + theToken,
        Content_Type: "application/x-www-form-urlencoded",
      },
    });
    const data = await response.json();
    return data;
  };

  // ** FUNCTION: Sent to component: Delete Envelope
  const deleteEnvelope = async (id) => {
    await deleteEnvelopeFromDB(id);

    // Only refresh envelopes if we're not in a bulk operation
    if (!isBulkOperationComplete) {
      getEnvelopes();
    }

    return true;
  };

  // ** FUNCTION (API): Reorder the envelopes / Update their Position fields.
  const reorderEnvelopesInDB = async (newEnvelopeOrder) => {
    const formData = new URLSearchParams();
    formData.append("envelopePositions", JSON.stringify(newEnvelopeOrder));
    const strURL = `${strURL_API}/envelopeReorder`;
    const response = await fetch(strURL, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        authorization: "Bearer " + theToken,
        Content_Type: "application/x-www-form-urlencoded",
      },
      body: formData,
      json: true,
    });
    if (response.ok) {
      console.log(response.status + " " + response.statusText);
    } else {
      const data = await response.json();
      console.log("Failed API Call. Status: " + response.status);
      console.log(
        "Failed API Call. StatusText: " + Object.values(response.statusText)
      );
      console.log(
        "Save Transaction | Failed API call. Message: " + data.message
      );
      console.table(data.errors);
    }
  };

  // ** FUNCTION: Sent to component: process the position/order of the envepes changing.
  const reorderEnvelopes = async (newEnvelopeOrder) => {
    await reorderEnvelopesInDB(newEnvelopeOrder);
  };

  // ** FUNCTION: Button: Show/Hide the Add Envelope portion of the screen.
  const showAdd_Click = () => {
    setEnvelope([]);
    setViewMode("manual");
    setShowAddEnvelope(true);
  };

  // ** FUNCTION: Button: Return to main screen.
  const returnToMain = () => {
    navigate("/main");
  };

  // ** FUNCTION: Sent to component: Edit an envelope.
  const editEnvelope = (envelope) => {
    setEnvelope(envelope);
    setViewMode("manual");
    setShowAddEnvelope(true);
  };

  // ** FUNCTION: Handle the Save action from the CategoryGrid component
  const handleCategoryGridSave = async () => {
    // Set the flag to indicate we're in a bulk operation
    setIsBulkOperationComplete(true);

    // Refresh envelopes list all at once after bulk operations
    await getEnvelopes();

    // Reset the flag
    setIsBulkOperationComplete(false);
  };

  // Switch view mode
  const switchToCommon = () => {
    setViewMode("common");
    setShowAddEnvelope(false); // Close add envelope dialog if open
  };

  const switchToManual = () => {
    setViewMode("manual");
  };

  // USEEFFECT (onStartup)
  useEffect(() => {
    getEnvelopes();
  }, []);

  if (loading) {
    return (
      <div
        className='container d-flex justify-content-center align-items-center'
        style={{ minHeight: "200px" }}>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className='container'>
      {/* Modal for adding/editing envelopes */}
      <AddEnvelope
        i_show={showAddEnvelope && viewMode === "manual"}
        i_envelope={envelope}
        fi_AddEnvelope={addEnvelope}
        fi_handleCancel={() => {
          setShowAddEnvelope(false);
        }}
      />

      {/* Button to return to main screen. */}
      <div className='d-flex justify-content-start mb-3'>
        <button className='btn btn-primary' onClick={returnToMain}>
          Return to Main Screen
        </button>
      </div>

      {/* View toggle buttons */}
      <div className='view-toggle-buttons'>
        <button
          className={`btn ${
            viewMode === "common" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={switchToCommon}>
          Common
        </button>
        <button
          className={`btn ${
            viewMode === "manual" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={switchToManual}>
          Manual
        </button>
      </div>

      {/* Button to show the add/edit envelope modal (only in manual mode) */}
      {viewMode === "manual" && (
        <div className='d-flex justify-content-center mb-3'>
          <button className='btn btn-primary' onClick={showAdd_Click}>
            Add Category
          </button>
        </div>
      )}

      {/* Common categories grid (shown when viewMode is "common") */}
      {viewMode === "common" && (
        <CategoryGrid
          userEnvelopes={envelopes}
          onSave={handleCategoryGridSave}
          onCancel={returnToMain}
          onEnvelopeDelete={deleteEnvelope}
          onEnvelopeAdd={addEnvelope}
        />
      )}

      {/* The listing of existing envelopes (shown when viewMode is "manual") */}
      {viewMode === "manual" && (
        <SetupEnvelopes
          i_envelopes={envelopes}
          fi_onDelete={deleteEnvelope}
          fi_editEnvelope={editEnvelope}
          fi_reorderEnvelopes={reorderEnvelopes}
        />
      )}
    </div>
  );
};

export default Envelope;
