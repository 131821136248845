import { useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({ onAdd, onTransfer, onFill, onBankImport }) => {
  const theToken = sessionStorage.getItem("token");
  const strURL_Logout = `${process.env.REACT_APP_API_URL}/api/logout`;
  let navigate = useNavigate();

  // ** FUNCTION (API): Sign out from server.
  const signOutFromServer = async () => {
    const response = await fetch(strURL_Logout, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Content_Type: "application/x-www-form-urlencoded",
        authorization: "Bearer " + theToken,
      },
    });
    const data = await response.json();

    return data;
  };

  const signOut = async () => {
    // Send command to sign out to server.
    await signOutFromServer;

    // Clear bearer token.
    sessionStorage.setItem("token", "");

    // Navigate to sign in / Sign up page.
    navigate("/");
  };

  return (
    <div className='collapse navbar-collapse' id='navmenu'>
      <div className='navbar-nav ms-auto'>
        <a className='nav-link' onClick={onAdd}>
          Add Transaction
        </a>
        <a className='nav-link' onClick={onTransfer}>
          Add Category Transfer
        </a>
        <a className='nav-link' onClick={onFill}>
          Fill Categories
        </a>
        <a className='nav-link' onClick={onBankImport}>
          Bank Import
        </a>
        {theToken && (
          <a className='nav-link' onClick={signOut}>
            Sign Out
          </a>
        )}
      </div>
    </div>
  );
};

export default Header;
