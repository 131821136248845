/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { BsCreditCard2Back } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
  getFromAPI,
  putToAPI,
  postToAPI,
  deleteTransFromAPI,
} from "../Components/lib/api.jsx";

import AddEnvelopeTrans from "../Components/AddEnvelopeTrans/AddEnvelopeTrans.jsx";
import AddTransaction from "../Components/AddTransaction/AddTransaction.jsx";
import FillEnvelopes from "../Components/FillEnvelopes/FillEnvelopes.jsx";
import Footer from "../Components/Footer/Footer.jsx";
import Header from "../Components/Header/Header.jsx";
import Envelopes from "./../Components/Envelopes/Envelopes.jsx";
import Transactions from "./../Components/Transactions/Transactions.jsx";
import Modal from "react-modal";
import "./Main.css";

const Main = () => {
  let theToken = sessionStorage.getItem("token");
  let strURL_getAccountInfo = `${process.env.REACT_APP_API_URL}/api/getAccountInfo`;
  let strURL_Envelopes = `${process.env.REACT_APP_API_URL}/api/envelope`;
  let strURL_Transactions = `${process.env.REACT_APP_API_URL}/api/transaction`;
  let strURL_TransactionEnvelopes = `${process.env.REACT_APP_API_URL}/api/transactionEnvelopes`;
  let strURL_EnvTransfer = `${process.env.REACT_APP_API_URL}/api/envelopeTransfer`;

  const [envelopes, setEnvelopes] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [errorOccured, setErrorOccured] = useState(false);
  const [currentTrans, setCurrentTrans] = useState({});
  const [currentEnvTrans, setCurrentEnvTrans] = useState({});
  const [showAddTrans, setShowAddTrans] = useState(false);
  const [showAddEvTransfer, setShowAddEvTransfer] = useState(false);
  const [showAddFill, setShowAddFill] = useState(false);
  const [accountHasTransactions, setAccountHasTransactions] = useState(false);
  const [loadingEnvelopes, setLoadingEnvelopes] = useState(true);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [accountHasExpired, setAccountHasExpired] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [messageBackground, setMessageBackground] = useState("#2b6777");
  const [showCloseButton, setShowCloseButton] = useState(true);
  const [showExpiredActionModal, setShowExpiredActionModal] = useState(false);
  const [userName, setUserName] = useState("");

  let navigate = useNavigate();

  const editEnvelopes = () => {
    navigate("/envelope");
  };

  // ** FUNCTION: Get account info from database.
  const getAccountInfo = async () => {
    const accountInfoFromServer = await getFromAPI(
      theToken,
      strURL_getAccountInfo
    );
    if (accountInfoFromServer.data.isRenewalPast) {
      setAccountHasExpired(true);
      setMessageText(
        "You're account is expired. Feel free to review your current finances, but to add/edit/delete any transactions, you'll need to renew your account first. Click XXX to renew."
      );
      setMessageBackground("#721c24");
      setShowCloseButton(false);
    }
    if (accountInfoFromServer.data.name) {
      setUserName(accountInfoFromServer.data.name);
    }
  };

  // ** FUNCTION: Get all envelopes from database.
  const getEnvelopes = async () => {
    setLoadingEnvelopes(true);
    const envelopesFromServer = await getFromAPI(theToken, strURL_Envelopes);
    setEnvelopes(envelopesFromServer);
    setLoadingEnvelopes(false);
  };

  // ** FUNCTION: Get all transactions from server.
  const getTransactions = async () => {
    setLoadingTransactions(true);
    const transactionsFromServer = await getFromAPI(
      theToken,
      strURL_Transactions
    );
    setTransactions(transactionsFromServer);

    if (transactionsFromServer && transactionsFromServer.length > 0) {
      setAccountHasTransactions(true);
    } else {
      setAccountHasTransactions(false);
    }
    setLoadingTransactions(false);
  };

  // ** FUNCTION (API): Save a new transaction to the server.
  const addTransToServer = async (transaction) => {
    const formData = new FormData();
    formData.append("payee", transaction.payee);
    formData.append("date", transaction.date);
    formData.append("checkNumber", transaction.checkNum);
    formData.append("totalAmount", transaction.totalAmount);
    formData.append("note", transaction.note);
    formData.append("envelope", JSON.stringify(transaction.destEnvelopes));
    formData.append("type", transaction.type);

    await postToAPI(theToken, strURL_Transactions, formData);
  };

  // ** FUNCTION (API): Update an existing transaction on the server.
  const updateTransOnServer = async (transaction) => {
    const formData = new URLSearchParams();
    formData.append("payee", transaction.payee);
    formData.append("date", transaction.date);
    formData.append("checkNumber", transaction.checkNum);
    formData.append("totalAmount", transaction.totalAmount);
    formData.append("note", transaction.note);
    formData.append("envelope", JSON.stringify(transaction.destEnvelopes));
    formData.append("type", transaction.type);

    await putToAPI(
      theToken,
      strURL_Transactions + "/" + transaction.id,
      formData
    );
  };

  // ** FUNCTION (API): Save a new envelope transfer to the database.
  const addEnvTransToServer = async (transaction) => {
    const formData = new FormData();
    formData.append("payee", transaction.payee);
    formData.append("sourceEnvelope", transaction.sourceEnvelope);
    formData.append("destEnvelopes", JSON.stringify(transaction.destEnvelopes));
    formData.append("date", transaction.date);
    formData.append("totalAmount", transaction.totalAmount);
    formData.append("note", transaction.note);
    formData.append("type", transaction.type);

    await postToAPI(theToken, strURL_EnvTransfer, formData);
  };

  // ** FUNCTION (API): Update an existing envelope transfer in the database.
  const updateEnvTransOnServer = async (transaction) => {
    const formData = new URLSearchParams();
    formData.append("payee", transaction.payee);
    formData.append("date", transaction.date);
    formData.append("totalAmount", transaction.totalAmount);
    formData.append("note", transaction.note);
    formData.append("sourceEnvelope", transaction.sourceEnvelope);
    formData.append("destEnvelopes", JSON.stringify(transaction.destEnvelopes));
    formData.append("type", transaction.type);

    await putToAPI(
      theToken,
      strURL_EnvTransfer + "/" + transaction.id,
      formData
    );
  };

  // ** FUNCTION (Passed): An envelope has been selected (clicked on). Only show transactions for the selected envelope.
  const selectEnvelope = async (id) => {
    let transactionsFromServer = await getFromAPI(
      theToken,
      `${strURL_Transactions}/${id}`
    );

    let envelopeLookup = (id) => {
      return envelopes.find((item) => item.id === id);
    };
    let balance = envelopeLookup(id).balance;
    let runningTotal;
    let transactions = [];
    transactionsFromServer.forEach((item) => {
      runningTotal = Object.assign(item, { runningBalance: balance });
      balance = balance - item.amount;
      transactions.push(runningTotal);
    });
    setTransactions(transactionsFromServer);
  };

  // ** FUNCTION (Passed): Save a newly entered transaction or update an existing transaction in the database.
  const saveTransaction = async (transaction) => {
    if (transaction.id > 0) {
      await updateTransOnServer(transaction);
    } else {
      await addTransToServer(transaction);
    }

    getEnvelopes();
    getTransactions();
    if (transaction.stayOpen === false) {
      setShowAddTrans(false);
    }
    setCurrentTrans({});
  };

  // ** FUNCTION (Passed): Edit a transaction. Opens the Edit a Transaction form.
  const editTransaction = async (transactionID) => {
    let obj = transactions.filter((o) => {
      return o.transactionID === transactionID;
    });

    const envelopes = await getFromAPI(
      theToken,
      `${strURL_TransactionEnvelopes}/${transactionID}`
    );
    obj.push(envelopes);

    switch (obj[0].type) {
      case parseInt(process.env.REACT_APP_Type_Transaction):
        setCurrentTrans(obj);
        setShowAddTrans(true);
        break;
      case parseInt(process.env.REACT_APP_Type_EnvTransfer):
        setCurrentEnvTrans(obj);
        setShowAddEvTransfer(true);
        break;
      case parseInt(process.env.REACT_APP_Type_UnallocatedFill):
      case parseInt(process.env.REACT_APP_Type_DepositFill):
        setCurrentTrans(obj);
        setShowAddFill(true);
        break;
      default:
        console.log("Transaction Type out of bounds.");
        break;
    }
  };

  // ** FUNCTION (Passed): Delete a transaction.
  const deleteTransaction = async (transactionID) => {
    await deleteTransFromAPI(
      theToken,
      `${strURL_Transactions}/${transactionID}`
    );
    getEnvelopes();
    getTransactions();
    setShowAddTrans(false);
    setShowAddEvTransfer(false);
    setShowAddFill(false);
    setCurrentTrans({});
    setCurrentEnvTrans({});
  };

  // ** FUNCTION (Passed): Save an envelope transfer or UPDATE an existing envelope transfer.
  const saveEvTransfer = async (transaction) => {
    if (transaction.id > 0) {
      const updatedEnvTransaction = await updateEnvTransOnServer(transaction);
    } else {
      const newEnvTransaction = await addEnvTransToServer(transaction);
    }
    getEnvelopes();
    getTransactions();
    setCurrentEnvTrans({});
    if (transaction.stayOpen === false) {
      setShowAddEvTransfer(false);
    }
  };

  // ** FUNCTION (Passed): Save a category Fill.
  const saveFill = async (transaction) => {
    // If this is from a new deposit...
    if (transaction.type == process.env.REACT_APP_Type_DepositFill) {
      if (transaction.id > 0) {
        await updateTransOnServer(transaction);
      } else {
        await addTransToServer(transaction);
      }
      // ...else this is a transfer from Unallocated.
    } else {
      if (transaction.id > 0) {
        await updateEnvTransOnServer(transaction);
      } else {
        console.log(transaction);
        await addEnvTransToServer(transaction);
      }
    }
    getEnvelopes();
    getTransactions();
    setShowAddFill(false);
    setCurrentTrans({});
  };

  // ** FUNCTION (Passed): Deal with user clicking 'Bank Import' in the Header.
  const handleBankImport = () => {
    if (accountHasExpired) {
      setShowExpiredActionModal(true);
    } else {
      navigate("/bankImport");
    }
  };

  useEffect(() => {
    if (theToken.trim().length === 0) {
      navigate("/");
    }
    getAccountInfo();
    getEnvelopes();
    getTransactions();
  }, []);

  Modal.setAppElement("#root");

  return (
    <div className='container'>
      {messageText && (
        <div
          className='message-alert'
          style={{ backgroundColor: messageBackground }}>
          <span>{messageText}</span>
          {showCloseButton && (
            <button
              onClick={() => setMessageText("")}
              className='message-alert__close-btn'>
              ×
            </button>
          )}
        </div>
      )}

      {/* Expired Account Action Modal */}
      <Modal
        isOpen={showExpiredActionModal}
        onRequestClose={() => setShowExpiredActionModal(false)}
        contentLabel='Account Expired Modal'>
        <div className='modal-expired'>
          <h2 className='modal-expired__title'>Account Expired</h2>
          <p className='modal-expired__message'>
            Your account has expired. Please renew your account to perform this
            action.
          </p>
          <button
            onClick={() => setShowExpiredActionModal(false)}
            className='modal-expired__close-btn'>
            Close
          </button>
        </div>
      </Modal>

      {/* Add Transaction Modal */}
      <AddTransaction
        i_show={showAddTrans}
        i_envelopes={envelopes}
        i_transaction={currentTrans}
        fi_saveTransaction={saveTransaction}
        fi_deleteTransaction={deleteTransaction}
        fi_handleCancel={() => {
          setShowAddTrans(false);
          setCurrentTrans({});
        }}
      />
      {/* Add Envelope Transfer Modal */}
      <AddEnvelopeTrans
        i_show={showAddEvTransfer}
        i_envelopes={envelopes}
        i_transaction={currentEnvTrans}
        fi_handleCancel={() => {
          setShowAddEvTransfer(false);
          setCurrentEnvTrans({});
        }}
        fi_saveEvTransfer={saveEvTransfer}
        fi_deleteTransaction={deleteTransaction}
      />
      {/* Fill Envelopes Modal */}
      <FillEnvelopes
        i_show={showAddFill}
        i_envelopes={envelopes}
        i_transaction={currentTrans}
        fi_handleCancel={() => {
          setShowAddFill(false);
          setCurrentTrans({});
        }}
        fi_saveFill={saveFill}
        fi_deleteTransaction={deleteTransaction}
      />
      <div className='mainPage'>
        <nav
          className='navbar navbar-expand-lg bg-dark navbar-dark p-2'
          id='mainNav'>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault();
              getTransactions();
            }}
            className='navbar-brand'>
            <span className='navbar-brand__title'>Easy Breezy Budget</span>
            {userName && (
              <span
                className='navbar-brand__username'
                onClick={() => navigate("/account")}
                style={{ cursor: "pointer" }}>
                {userName}
              </span>
            )}
          </a>

          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navmenu'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <Header
            onAdd={() => {
              if (accountHasExpired) {
                setShowExpiredActionModal(true);
              } else {
                setShowAddTrans(true);
              }
            }}
            onTransfer={() => {
              if (accountHasExpired) {
                setShowExpiredActionModal(true);
              } else {
                setShowAddEvTransfer(true);
              }
            }}
            onFill={() => {
              if (accountHasExpired) {
                setShowExpiredActionModal(true);
              } else {
                setShowAddFill(true);
              }
            }}
            onBankImport={handleBankImport}
          />
        </nav>

        <div className='mainEnvelopes'>
          <button className='btn btn-primary' onClick={editEnvelopes}>
            Edit Categories
          </button>
          <Envelopes
            envelopes={envelopes}
            selectEnvelope={selectEnvelope}
            loading={loadingEnvelopes}
          />
        </div>

        <div className='mainTransactions'>
          <Transactions
            i_transactions={transactions}
            fi_openEdit={editTransaction}
            i_showMessage={!accountHasTransactions}
            loading={loadingTransactions}
          />
        </div>

        <div className='mainFooter text-light'>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Main;
