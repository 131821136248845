import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFromAPI, putToAPI } from "../Components/lib/api.jsx";
import "./Account.css";

const Account = () => {
  const navigate = useNavigate();
  const theToken = sessionStorage.getItem("token");
  const strURL_getAccountInfo = `${process.env.REACT_APP_API_URL}/api/getAccountInfo`;
  const strURL_updateAccountInfo = `${process.env.REACT_APP_API_URL}/api/updateAccountInfo`;

  // State variables for account information
  const [name, setName] = useState("");
  const [accountLevel, setAccountLevel] = useState(1);
  const [language, setLanguage] = useState(1);
  const [currency, setCurrency] = useState(1);
  const [expirationDate, setExpirationDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);

  // Get account info from database
  const getAccountInfo = async () => {
    setIsLoading(true);
    try {
      const accountInfoFromServer = await getFromAPI(
        theToken,
        strURL_getAccountInfo
      );
      if (accountInfoFromServer.data) {
        setName(accountInfoFromServer.data.name || "");
        setAccountLevel(accountInfoFromServer.data.accountLevel || 1);
        setLanguage(accountInfoFromServer.data.language || 1);
        setCurrency(accountInfoFromServer.data.currency || 1);
        setExpirationDate(accountInfoFromServer.data.expireDate || "");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (theToken.trim().length === 0) {
      navigate("/");
    }
    getAccountInfo();
  }, []);

  const handleNameChange = (e) => {
    const value = e.target.value.slice(0, 20); // Limit to 20 characters
    setName(value);
    // Reset status messages when user starts editing
    setSaveError("");
    setSaveSuccess(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setSaveError("");
    setSaveSuccess(false);

    try {
      const formData = new URLSearchParams(); // formData must be of type URLSearchParams for the PUT, instead of the FormData type (like I normally use)
      formData.append("name", name.trim());

      await putToAPI(theToken, strURL_updateAccountInfo, formData);
      setSaveSuccess(true);

      // Refresh account info to get updated data
      await getAccountInfo();
    } catch (error) {
      const errorText =
        error.text + " (" + error.status + ")" ||
        "An unexpected server error occurred";
      setSaveError(errorText);
      console.error("Save error:", error.text);
    } finally {
      setIsSaving(false);
    }
  };

  const getAccountLevelLabel = (level) => {
    const levels = {
      1: "Trial",
      2: "Paid (Monthly)",
      3: "Paid (Yearly)",
      6: "Canceled",
      7: "Expired",
    };
    return levels[level] || "Unknown";
  };

  const getLanguageLabel = (lang) => {
    const languages = {
      1: "English",
      2: "Spanish",
    };
    return languages[lang] || "Unknown";
  };

  const getCurrencyLabel = (curr) => {
    const currencies = {
      1: "US Dollar (USD)",
      2: "Canadian Dollar (CAD)",
      3: "Euro",
      4: "British Pound",
    };
    return currencies[curr] || "Unknown";
  };

  // Format the date to be more readable
  const formatDate = (dateString) => {
    if (!dateString) return "Not Set";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className='account-container'>
      <div className='account-header'>
        <h1>Account Settings</h1>
        <button className='back-button' onClick={() => navigate("/main")}>
          Back to Main
        </button>
      </div>

      <div className='account-form'>
        {isLoading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ minHeight: "200px" }}>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {/* Name */}
            <div className='form-group'>
              <label>Name:</label>
              <input
                type='text'
                value={name}
                onChange={handleNameChange}
                maxLength={20}
                className='form-control'
              />
            </div>

            {/* Language */}
            <div className='form-group'>
              <label>Language:</label>
              <div className='static-value'>{getLanguageLabel(language)}</div>
            </div>

            {/* Currency */}
            <div className='form-group'>
              <label>Currency:</label>
              <div className='static-value'>{getCurrencyLabel(currency)}</div>
            </div>

            {/* Account Level */}
            <div className='form-group'>
              <label>Account Level:</label>
              <div className='static-value'>
                {getAccountLevelLabel(accountLevel)}
              </div>
            </div>

            {/* Expiration Date */}
            <div className='form-group'>
              <label>Expiration Date:</label>
              <div className='static-value'>{formatDate(expirationDate)}</div>
            </div>

            {/* Save Button and Status Messages */}
            <div className='form-group mt-4'>
              <button
                className='btn btn-primary'
                onClick={handleSave}
                disabled={isSaving || !name.trim()}>
                {isSaving ? "Saving..." : "Save Changes"}
              </button>

              {saveSuccess && (
                <div className='alert alert-success mt-2'>
                  Changes saved successfully!
                </div>
              )}

              {saveError && (
                <div className='alert alert-danger mt-2'>{saveError}</div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Account;
