import React, { useState, useEffect, useMemo } from "react";
import "./CategoryGrid.css";
import { PRESET_ENVELOPES } from "./presetEnvelopes";

const CategoryGrid = ({
  userEnvelopes,
  onSave,
  onCancel,
  onEnvelopeDelete,
  onEnvelopeAdd,
}) => {
  // Generate the list of common categories from PRESET_ENVELOPES
  // Filter out categories that already exist in user's account
  const commonCategories = useMemo(() => {
    const existingCategoryNames = userEnvelopes.map((env) => env.envelopeName);

    return Object.entries(PRESET_ENVELOPES)
      .filter(([name]) => !existingCategoryNames.includes(name))
      .map(([name, data]) => ({
        name,
        icon: data.icon,
        type: data.type,
      }));
  }, [userEnvelopes]);

  // Track selected categories
  const [selectedCategories, setSelectedCategories] = useState([]);
  // Track categories to be deleted
  const [categoriesToDelete, setCategoriesToDelete] = useState([]);
  // Track whether we're currently saving
  const [isSaving, setIsSaving] = useState(false);

  // Initialize selected categories based on what the user already has
  useEffect(() => {
    const initialSelected = [];

    userEnvelopes.forEach((userEnvelope) => {
      const matchingCategory = Object.entries(PRESET_ENVELOPES)
        .map(([name, data]) => ({ name, icon: data.icon, type: data.type }))
        .find((category) => category.name === userEnvelope.envelopeName);

      if (matchingCategory) {
        initialSelected.push({
          ...matchingCategory,
          id: userEnvelope.id,
          exists: true,
        });
      }
    });

    setSelectedCategories(initialSelected);
  }, [userEnvelopes]);

  // Toggle category selection
  const toggleCategory = (category) => {
    const isSelected = selectedCategories.some(
      (selected) => selected.name === category.name
    );

    if (isSelected) {
      // Find if this category exists in user's envelopes
      const existingEnvelope = userEnvelopes.find(
        (envelope) => envelope.envelopeName === category.name
      );

      if (existingEnvelope) {
        // Add to delete list if it exists
        setCategoriesToDelete((prev) => [...prev, existingEnvelope.id]);
      }

      // Remove from selected
      setSelectedCategories((prev) =>
        prev.filter((selected) => selected.name !== category.name)
      );
    } else {
      // Check if it was previously marked for deletion
      const wasMarkedForDeletion = userEnvelopes.find(
        (envelope) =>
          envelope.envelopeName === category.name &&
          categoriesToDelete.includes(envelope.id)
      );

      if (wasMarkedForDeletion) {
        // Remove from delete list
        setCategoriesToDelete((prev) =>
          prev.filter((id) => id !== wasMarkedForDeletion.id)
        );
      }

      // Add to selected
      const existingEnvelope = userEnvelopes.find(
        (envelope) => envelope.envelopeName === category.name
      );

      setSelectedCategories((prev) => [
        ...prev,
        {
          ...category,
          id: existingEnvelope?.id || null,
          exists: !!existingEnvelope,
        },
      ]);
    }
  };

  const handleSave = async () => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      // First, process all deletions in batch
      if (categoriesToDelete.length > 0) {
        const deletePromises = categoriesToDelete.map((id) =>
          onEnvelopeDelete(id)
        );
        await Promise.all(deletePromises);
      }

      // Then, prepare all new categories to add
      const categoriesToAdd = selectedCategories.filter((c) => !c.exists);

      // Create array of envelope objects to add
      const envelopesToAdd = categoriesToAdd
        .map((category) => {
          const presetData = PRESET_ENVELOPES[category.name];

          if (!presetData) return null;

          const envelope = {
            envelopeID: 0, // New envelope
            envelopeName: category.name,
            position: 0, // Default position
            type: presetData.type,
            fullAmount: presetData.fullAmount * 100, // Convert to cents
            showProgress: presetData.showProgress ? 1 : 0,
            showProgressTick: presetData.showProgressTick ? 1 : 0,
            timeFrame: presetData.timeFrame,
            dueDate: "", // Initialize with empty string
          };

          // Set due date based on timeFrame if showProgressTick is true
          if (presetData.showProgressTick) {
            const today = new Date();
            switch (presetData.timeFrame) {
              case 1: // Weekly
                envelope.dueDate = new Date(today.setDate(today.getDate() + 7))
                  .toISOString()
                  .slice(0, 10);
                break;
              case 2: // Monthly
                envelope.dueDate = new Date(
                  today.setMonth(today.getMonth() + 1)
                )
                  .toISOString()
                  .slice(0, 10);
                break;
              case 3: // Quarterly
                envelope.dueDate = new Date(
                  today.setMonth(today.getMonth() + 3)
                )
                  .toISOString()
                  .slice(0, 10);
                break;
              case 4: // Yearly
                envelope.dueDate = new Date(
                  today.setFullYear(today.getFullYear() + 1)
                )
                  .toISOString()
                  .slice(0, 10);
                break;
              default:
                envelope.dueDate = new Date(today.setDate(today.getDate() + 7))
                  .toISOString()
                  .slice(0, 10);
            }
          }

          return envelope;
        })
        .filter((env) => env !== null);

      // If we have envelopes to add, add them all at once using Promise.all
      if (envelopesToAdd.length > 0) {
        // Use Promise.all to make all API calls in parallel
        await Promise.all(
          envelopesToAdd.map((envelope) => onEnvelopeAdd(envelope))
        );
      }

      // Call the onSave function to refresh the UI
      onSave();

      // Navigate back to main screen
      onCancel();
    } catch (error) {
      console.error("Error saving categories:", error);
      setIsSaving(false);
    }
  };

  return (
    <div className='category-grid-container'>
      <div className='category-grid'>
        {commonCategories.length > 0 ? (
          commonCategories.map((category, index) => {
            const isSelected = selectedCategories.some(
              (selected) => selected.name === category.name
            );

            return (
              <div
                key={index}
                className={`category-tile ${isSelected ? "selected" : ""}`}
                onClick={() => toggleCategory(category)}>
                <div className='category-icon'>{category.icon}</div>
                <div className='category-name'>{category.name}</div>
                <div className='category-type'>
                  {category.type === 1 ? "Spending" : "Saving"}
                </div>
              </div>
            );
          })
        ) : (
          <div className='no-categories-message'>
            <p>
              You've already added all available preset categories to your
              account.
            </p>
            <p>You can create custom categories using the "Manual" option.</p>
          </div>
        )}
      </div>

      <div className='button-container'>
        <button
          className='btn btn-primary'
          onClick={handleSave}
          disabled={selectedCategories.length === 0 || isSaving}>
          {isSaving ? "Saving..." : "Save Changes"}
        </button>
        <button className='btn btn-secondary' onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CategoryGrid;
