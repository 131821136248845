// Import icons
import {
  FaShoppingBasket,
  FaHome,
  FaWrench,
  FaCar,
  FaGamepad,
  FaCreditCard,
  FaPiggyBank,
  FaPlane,
  FaUniversity,
  FaUtensils,
  FaStethoscope,
  FaPhoneAlt,
  FaTshirt,
  FaPaw,
  FaNewspaper,
  FaCarAlt,
  FaGift,
  FaGraduationCap,
  FaTools,
  FaHandsHelping,
  FaLightbulb,
  FaGasPump,
} from "react-icons/fa";
import React from "react";

// Spending Categories
// Types:
//    Spending=1
//    Saving = 2
// timeFrame
//    Weekly = 1
//    Monthly = 2
//    Quarterly = 3
//    Yearly = 4

export const PRESET_ENVELOPES = {
  Groceries: {
    type: 1,
    fullAmount: 250,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 1, // Weekly
    icon: <FaShoppingBasket size={48} />,
  },
  "Rent/Mortgage": {
    type: 1,
    fullAmount: 1500,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 2, // Monthly
    icon: <FaHome size={48} />,
  },
  "Transportation/Fuel": {
    type: 1,
    fullAmount: 60,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 1,
    icon: <FaGasPump size={48} />,
  },
  Utilities: {
    type: 1,
    fullAmount: 200,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 2, // Monthly
    icon: <FaLightbulb size={48} />,
  },
  Entertainment: {
    type: 1,
    fullAmount: 100,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 1, // Weekly
    icon: <FaGamepad size={48} />,
  },
  "Credit Card": {
    type: 1,
    fullAmount: 400,
    showProgress: false,
    showProgressTick: false,
    timeFrame: 1,
    icon: <FaCreditCard size={48} />,
  },
  // Savings Categories
  "Emergency Fund": {
    type: 2,
    fullAmount: 5000,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 1, // Not applicable but default to weekly
    icon: <FaPiggyBank size={48} />,
  },
  Charity: {
    type: 2,
    fullAmount: 500,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 2,
    icon: <FaHandsHelping size={48} />,
  },
  Vacation: {
    type: 2,
    fullAmount: 2000,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 4, // Yearly
    icon: <FaPlane size={48} />,
  },
  "Home Down Payment": {
    type: 2,
    fullAmount: 20000,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 4, // Yearly
    icon: <FaHome size={48} />,
  },
  "Dining Out": {
    type: 1,
    fullAmount: 200,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 2,
    icon: <FaUtensils size={48} />,
  },
  Healthcare: {
    type: 1,
    fullAmount: 150,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 2, // Monthly
    icon: <FaStethoscope size={48} />,
  },
  "Phone/Internet": {
    type: 1,
    fullAmount: 120,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 2, // Monthly
    icon: <FaPhoneAlt size={48} />,
  },
  Clothing: {
    type: 1,
    fullAmount: 100,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 2, // Monthly
    icon: <FaTshirt size={48} />,
  },
  "Pet Expenses": {
    type: 1,
    fullAmount: 50,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 2, // Monthly
    icon: <FaPaw size={48} />,
  },
  Subscriptions: {
    type: 1,
    fullAmount: 50,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 2, // Monthly
    icon: <FaNewspaper size={48} />,
  },

  // Savings Categories
  "Car Replacement": {
    type: 2,
    fullAmount: 10000,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 4, // Yearly
    icon: <FaCarAlt size={48} />,
  },
  "Holiday Gifts": {
    type: 2,
    fullAmount: 1200,
    showProgress: true,
    showProgressTick: true,
    timeFrame: 4, // Yearly
    icon: <FaGift size={48} />,
  },
  "Education Fund": {
    type: 2,
    fullAmount: 5000,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 4, // Yearly
    icon: <FaGraduationCap size={48} />,
  },
  "Home Maintenance": {
    type: 2,
    fullAmount: 2000,
    showProgress: true,
    showProgressTick: false,
    timeFrame: 3, // Quarterly
    icon: <FaTools size={48} />,
  },
};
