// Helper function for consistent error logging
const logError = (response, data, operation = "") => {
  console.log(`Failed API Call. Status: ${response.status}`);
  console.log(`Failed API Call. StatusText: ${response.statusText}`);
  console.log(`${operation} | Failed API call. Message: ${data.message}`);
  if (data.errors) {
    console.table(data.errors);
  }
};

// Helper function for consistent success logging
const logSuccess = (response) => {
  console.log(`${response.status} ${response.statusText}`);
};

// ** Perform a GET from API server.
export const getFromAPI = async (theToken, strURL) => {
  const response = await fetch(strURL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      authorization: "Bearer " + theToken,
    },
  });

  const data = await response.json();

  if (response.ok) {
    logSuccess(response);
    return data;
  } else {
    logError(response, data, "GET");
    throw {
      status: response.status,
      text: data.message,
    };
  }
};

// ** Perform a PUT to the API server.
// FormData MUST come over as a URLSearchParams type, not the formData type.
export const putToAPI = async (theToken, strURL, formData) => {
  const response = await fetch(strURL, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      authorization: "Bearer " + theToken,
    },
    body: formData,
  });

  if (response.ok) {
    logSuccess(response);
    return {
      status: response.status,
      text: response.statusText,
    };
  } else {
    const data = await response.json();
    logError(response, data, "PUT");
    throw {
      status: response.status,
      text: data.message,
    };
  }
};

// ** Perform a POST to the API server.
export const postToAPI = async (theToken, strURL, formData) => {
  const response = await fetch(strURL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      authorization: "Bearer " + theToken,
    },
    body: formData,
  });

  const data = await response.json();

  if (response.ok) {
    logSuccess(response);
    return {
      status: response.status,
      text: data.message,
    };
  } else {
    logError(response, data, "POST");
    throw {
      status: response.status,
      text: data.message,
    };
  }
};

// ** Perform a DELETE to the API Server.
export const deleteTransFromAPI = async (theToken, strURL) => {
  const response = await fetch(strURL, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      authorization: "Bearer " + theToken,
    },
  });

  if (response.ok) {
    logSuccess(response);
    return {
      status: response.status,
      text: response.statusText,
    };
  } else {
    const data = await response.json();
    logError(response, data, "DELETE");
    throw {
      status: response.status,
      text: data.message,
    };
  }
};
