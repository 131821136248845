import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let navigate = useNavigate();

  return (
    <div className='privacy-policy-page'>
      {/* Navigation */}
      <nav
        className='navbar navbar-expand-lg navbar-dark'
        style={{ backgroundColor: "#2b6777" }}>
        <div className='container'>
          <a className='navbar-brand' href='/'>
            Easy Breezy Budget
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item'>
                <a className='nav-link' href='/'>
                  Home
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Privacy Policy Content */}
      <div className='privacy-content'>
        <div className='container'>
          <h1>Privacy Policy</h1>
          <p>Last updated: August 1st 2024</p>

          <section>
            <h2>1. Introduction</h2>
            <p>
              Welcome to Easy Breezy Budget. We are committed to protecting your
              personal information and your right to privacy. This Privacy
              Policy explains how we collect, use, disclose, and safeguard your
              information when you use our service.
            </p>
          </section>

          <section>
            <h2>2. Information We Collect</h2>
            <p>
              We collect personal information that you provide to us, such as:
            </p>
            <ul>
              <li>Account information (e.g., name, email address)</li>
              <li>
                Financial information (e.g., transaction data, account balances)
              </li>
              <li>Usage data (e.g., how you interact with our service)</li>
            </ul>
          </section>

          <section>
            <h2>3. How We Use Your Information</h2>
            <p>We use your information to:</p>
            <ul>
              <li>Provide and maintain our service</li>
              <li>Improve and personalize your experience</li>
              <li>Communicate with you about our service</li>
              <li>Ensure the security of our service</li>
            </ul>
          </section>

          <section>
            <h2>4. How We Share Your Information</h2>
            <p>
              We do not sell your personal information. We may share your
              information with:
            </p>
            <ul>
              <li>Legal and regulatory authorities, when required by law</li>
            </ul>
          </section>

          <section>
            <h2>5. Your Rights and Choices</h2>
            <p>You have the right to:</p>
            <ul>
              <li>Access and update your personal information</li>
              <li>Request deletion of your data</li>
            </ul>
          </section>

          <section>
            <h2>6. Security</h2>
            <p>
              We implement appropriate technical and organizational measures to
              protect your personal information. However, no method of
              transmission over the Internet or electronic storage is 100%
              secure.
            </p>
          </section>

          <section>
            <h2>7. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page
              and updating the "Last updated" date.
            </p>
          </section>

          <section>
            <h2>8. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at:
            </p>
            <p>Email: support_team@easybreezybudget.com</p>
          </section>
        </div>
      </div>

      {/* Footer */}
      <footer
        className='py-4'
        style={{ backgroundColor: "#2b6777", color: "#ffffff" }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h5>Easy Breezy Budget</h5>
              <p>Simplifying personal finance management for everyone.</p>
            </div>
            <div className='col-md-3'>
              <h5>Contact</h5>
              <ul className='list-unstyled'>
                <li>Email: support@easybreezybudget.com</li>
              </ul>
            </div>
          </div>
          <hr style={{ backgroundColor: "#ffffff" }} />
          <div className='text-center'>
            <p>
              &copy; {new Date().getFullYear()} 1034Enterprises. All rights
              reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
